// import React from 'react'
// import PlayButton from 'playButton'

// function Chatroom() {
//   return (
//     <div>
//         <script id="cid0020000370658131521" data-cfasync="false" async src="//st.chatango.com/js/gz/emb.js" style="width: 100%;height: 100%;">{"handle":"distortlive","arch":"js","styles":{"a":"9999ff","b":48,"c":"000000","d":"000000","f":48,"i":48,"k":"9999ff","l":"9999ff","m":"9999ff","o":48,"p":"10","q":"9999ff","r":48,"t":0,"usricon":0,"surl":0,"allowpm":0,"fwtickm":1}}</script>
//         <div className='chatRoomPlayButton'>
//             <PlayButton />
//         </div>
//     </div>
//   )
// }

// export default Chatroom

// import React from 'react';
// import PlayButton from './playButton'; // Ensure the path is correct

// function Chatroom() {
//   return (
//     <div>
//         <div 
//           dangerouslySetInnerHTML={{ 
//             __html: `
//               <script id="cid0020000370658131521" data-cfasync="false" async src="//st.chatango.com/js/gz/emb.js" style="width: 100%; height: 100%;">
//                 {"handle":"distortlive","arch":"js","styles":{"a":"9999ff","b":48,"c":"000000","d":"000000","f":48,"i":48,"k":"9999ff","l":"9999ff","m":"9999ff","o":48,"p":"10","q":"9999ff","r":48,"t":0,"usricon":0,"surl":0,"allowpm":0,"fwtickm":1}}
//               </script>
//             `
//           }} 
//         />
//         <div className='chatRoomPlayButton'>
//             <PlayButton />
//         </div>
//     </div>
//   );
// }

// export default Chatroom;

// import React, { useEffect } from 'react';
// import PlayButton from './playButton'; // Make sure the import path matches your file structure

// function Chatroom() {
//   useEffect(() => {
//     // Create script element
//     const script = document.createElement('script');
//     script.id = 'cid0020000370658131521';
//     script.async = true;
//     script.setAttribute('data-cfasync', 'false');
//     script.src = '//st.chatango.com/js/gz/emb.js';
//     // Convert the JavaScript object to a JSON string for the script content
//     script.innerHTML = JSON.stringify({
//       "handle": "distortlive",
//       "arch": "js",
//       "styles": {
//         "a": "9999ff",
//         "b": 48,
//         "c": "000000",
//         "d": "000000",
//         "f": 48,
//         "i": 48,
//         "k": "9999ff",
//         "l": "9999ff",
//         "m": "9999ff",
//         "o": 48,
//         "p": 10,
//         "q": "9999ff",
//         "r": 48,
//         "t": 0,
//         "usricon": 0,
//         "surl": 0,
//         "allowpm": 0,
//         "fwtickm": 1
//       }
//     });

//     // Append the script to the document body
//     document.body.appendChild(script);

//     // Cleanup function to remove the script when the component unmounts
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []); // Ensures this effect runs only once after the initial render

//   return (
//     <div className="chatroom-container">
//         <div className='chatRoomPlayButton'>
//             <PlayButton />
//         </div>
//     </div>
//   );
// }

// export default Chatroom;

import React, { useEffect } from 'react';
import PlayButton from './playButton'; // Ensure this path is correct for your project

function Chatroom() {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'cid0020000370658131521';
    script.async = true;
    script.setAttribute('data-cfasync', 'false');
    script.src = '//st.chatango.com/js/gz/emb.js';
    script.innerHTML = JSON.stringify({
      "handle": "distortlive",
      "arch": "js",
      "styles": {
        "a": "9999ff",
        "b": 48,
        "c": "000000",
        "d": "000000",
        "f": 48,
        "i": 48,
        "k": "9999ff",
        "l": "9999ff",
        "m": "9999ff",
        "o": 48,
        "p": 10,
        "q": "9999ff",
        "r": 48,
        "t": 0,
        "usricon": 0,
        "surl": 0,
        "allowpm": 0,
        "fwtickm": 1
      }
    });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="chatroom-container">
        <div className='chatRoomPlayButton'>
            <PlayButton />
        </div>
    </div>
  );
}

export default Chatroom;