import React, { useState } from 'react';
import {BrowserRouter as Router, Routes, Route, Link} from "react-router-dom"

function DropdownMenu() {
  // State to track the dropdown visibility
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className='dropdown'>
      <img onClick={toggleDropdown} src={require('./menuicon.png')} alt='menu' hight={50} width={50} />

      {isDropdownOpen && (
        <div className='dropdownContent'>
          <nav>
            <div>
              <Link to="/" className='dropdownElement'>Radio</Link>
            </div>
            <div>
              <Link to="/timetable" className='dropdownElement'>Timetable</Link>
            </div>
            <div>
              <Link to="/about" className='dropdownElement'>About</Link>
            </div>
            {/* <div>
              <Link to="/chatroom" className='dropdownElement'>Chatroom</Link>
            </div> */}
            <div>
              <Link to="https://www.instagram.com/distort.live/">Instagram</Link>
            </div>
            {/* <div>
              <Link to="https://soundcloud.com/distort-archive">Archive</Link>
            </div> */}
          </nav>
          
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;