import React, { useState, createContext } from 'react';

const AudioContext = createContext();
export { AudioContext };

function AudioProvider({ children }) {
  const [audio, setAudio] = useState('PAUSED'); // Initial theme state

  const toggleAudio = () => {
    setAudio(prevAudio => (prevAudio === 'PAUSED' ? 'PLAYING' : 'PAUSED'));
  };

  // The context value includes both the state and the function to modify it
  const value = { audio, toggleAudio };

  return (
    <AudioContext.Provider value={value}>
      {children}
    </AudioContext.Provider>
  );
}

export { AudioProvider };