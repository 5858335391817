import React from 'react'
import { Routes, Route, Link} from 'react-router-dom'
import DropdownMenu from './components/dropdownMenu'
import Radio from './components/radio'
import About from './components/about'
import Timetable from './components/timetable'
import './components/styles.css'
import Overlay from './components/overlay'
import { AudioProvider, AudioContext } from './components/audioContextProvider'
import AudioPlayerComponent from './components/audioPlayer'
import Chatroom from './components/chatroom'

function App() {
  return (
    <div className="App">
      <AudioProvider>
        <AudioPlayerComponent audioSrc='https://a5.asurahosting.com:8330/radio.mp3'/>
        <Overlay />
          <Routes>
            <Route path="/" element={<Radio />} />
            <Route path="/about" element={<About />} />
            <Route path="/timetable" element={<Timetable />} />
            <Route path="/chatroom" element={<Chatroom />} />
          </Routes>
      </AudioProvider>
    </div>
  );
}

export default App;
