import React from 'react'
import {Link} from 'react-router-dom'
function DistortLogo() {

  function clickHandler() {
    console.log('clicked!')
  }

  return (
    <div >
      <Link to='/'className='logoContainer'>
        <img src={require('./distortlogo.png')} alt='distort logo' onClick={clickHandler}className='distortLogo' />
        {/* <img src={require('./distortlogo.png')} alt='distort logo' onClick={clickHandler}className='distortLogo' />
        <img src={require('./distortlogo.png')} alt='distort logo' onClick={clickHandler}className='distortLogo' />
        <img src={require('./distortlogo.png')} alt='distort logo' onClick={clickHandler}className='distortLogo' style={{opacity: 0.9}} />
        <img src={require('./distortlogo.png')} alt='distort logo' onClick={clickHandler}className='distortLogo' style={{opacity: 0.8}} />
        <img src={require('./distortlogo.png')} alt='distort logo' onClick={clickHandler}className='distortLogo' style={{opacity: 0.6}} />
        <img src={require('./distortlogo.png')} alt='distort logo' onClick={clickHandler}className='distortLogo' style={{opacity: 0.4}} />
        <img src={require('./distortlogo.png')} alt='distort logo' onClick={clickHandler}className='distortLogo' style={{opacity: 0.2}} /> */}
      </Link>
    </div>
  )
}


export default DistortLogo