// import React, { useContext } from 'react';
// import { AudioContext } from './audioContextProvider';

// function PlayButton() {
//   const { audio, toggleAudio } = useContext(AudioContext);
  
//   const pauseSrc = '/pauseButton_256_no_background.png';
//   const playSrc = '/graffplay_256_square.png';
//   let imgUsed;

//   if (audio === 'PLAYING') {
//     imgUsed = pauseSrc;

//   } else {
//     imgUsed = playSrc;
//   }

//   return (
//     <img src={imgUsed} onClick={toggleAudio} alt='play/pause' />
//   );
// }

// export default PlayButton;

import React, { useContext } from 'react';
import { AudioContext } from './audioContextProvider';

function PlayButton() {
  const { audio, toggleAudio } = useContext(AudioContext);

  const pauseSrc = '/pauseButton_256_no_background.png';
  const playSrc = '/graffplay_256_square.png';

  // Determine which image to use based on the 'audio' state
  const imgUsed = audio === 'PLAYING' ? pauseSrc : playSrc;

  // Wrapper function that logs the current state and then toggles it
  const handleClick = () => {
    console.log(`Current audio state before toggle: ${audio}`); // Log the current state
    toggleAudio(); // Then toggle the state
  };

  return (
    <img src={imgUsed} onClick={handleClick} alt='play/pause' />
  );
}

export default PlayButton;
