import React, { useRef, useEffect, useContext } from 'react';
import { AudioContext } from './audioContextProvider';

function AudioPlayerComponent({ audioSrc }) {
  const audioRef = useRef(null);
  const { audio } = useContext(AudioContext);

  useEffect(() => {
    // Initialize audioRef.current if it hasn't been initialized yet or if audioSrc changes
    if (!audioRef.current || audioRef.current.src !== audioSrc) {
      audioRef.current = new Audio(audioSrc);
    }

    // Function to play or pause the audio
    const playOrPauseAudio = () => {
      if (audio === 'PLAYING') {
        // Refresh the audio source every time before playing
        audioRef.current.src = audioSrc; // Update the audio source to refresh it
        audioRef.current.load(); // Load the new source

        // Attempt to play the audio
        const playPromise = audioRef.current.play();

        // Catch and ignore the error if play() is interrupted by a pause() call
        if (playPromise !== undefined) {
          playPromise.then(() => {
            // Playback started successfully
          }).catch(error => {
            console.log("Playback was interrupted:", error.message);
          });
        }
      } else {
        // Pause the audio
        audioRef.current.pause();
      }
    };

    // Call playOrPauseAudio whenever the audio state changes
    playOrPauseAudio();
  }, [audio, audioSrc]); // This effect depends on 'audio' state and 'audioSrc' prop changes

  return null; // This component does not render anything itself
}

export default AudioPlayerComponent;
