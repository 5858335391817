import React from 'react'
import DistortLogo from './distortLogo'

function About() {
  return (
    <div className='aboutWrapper'>
      <div className='aboutText'>
        <p>Distort is an Independent online radio platform that aims to create a hub for the current underground sounds of the uk. </p>
        <p>Originating in Bristol, Distort started off as an idea between a couple of mates, but has finally materialised after a year of work and planning.</p>
      </div>
    </div>
  )
}

export default About