import React from 'react';
import DistortLogo from './distortLogo'
import AudioPlayerComponent from './audioPlayer.js'
import AudioPlayer from './audioPlayer.js'
import NowPlaying from './nowPlaying.js'
import PlayButton from './playButton'
import Cosies from './cosies'

function Radio() {
  return (
    <div className='radioWrapper'>
      <DistortLogo />
      <div className='playerButton'>
        {/* <AudioPlayer src='https://a5.asurahosting.com:8330/radio.mp3' /> */}
        {/* <AudioPlayerComponent audioSrc="https://a5.asurahosting.com:8330/radio.mp3" playImageSrc="graffplay_256_square.png" pauseImageSrc="pauseButton_256_no_background.png" /> */}
        <PlayButton />
        <NowPlaying />

      </div>
      {/* <iframe width='195px' className='azuraController' src="https://a5.asurahosting.com/public/distort.live/embed" frameborder="0" allowtransparency="true"></iframe> */}
      
      {/* <a href='https://www.instagram.com/distort.live/'>
                <img src={require('./instagram.png')} className='socialButton' />
      </a> */}
      {/* <Cosies /> */}
    </div>
  )
}

export default Radio