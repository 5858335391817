import React from 'react'
import DropdownMenu from './dropdownMenu'
import DistortLogo from './distortLogo'

function Overlay() {
  return (
    <div className='overlay'>
        <DropdownMenu />
        <DistortLogo />
        <div className='socialWrapper'>
            {/* <a href='https://soundcloud.com/discover'>
                <img src={require('./soundcloud.png')} className='socialButton' />
            </a>     */}
        </div>
        
    </div>
  )
}

export default Overlay