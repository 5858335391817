import React from 'react'
import DropdownMenu from './dropdownMenu'
import DistortLogo from './distortLogo'
import TimetableButton from './timetableButton'

function Timetable() {
  return (
      <div className='timetableWrapper'>
        <iframe src="https://calendar.google.com/calendar/embed?src=364bcbc541b9d70317fafe6a6a81afba0f7baec2f26d1199fd5372a426afa1f9%40group.calendar.google.com&ctz=Europe%2FLondon" width="800" height="600" frameborder="0" scrolling="no"></iframe>
      </div>
  )
}

export default Timetable