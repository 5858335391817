
import React, { useEffect, useState } from 'react';

function NowPlaying() {
  const [nowPlaying, setNowPlaying] = useState({});
  const azuracastAPI = `https://a5.asurahosting.com/api/nowplaying/distort.live`; // Replace with your AzuraCast URL

  useEffect(() => {
    const fetchNowPlaying = async () => {
      try {
        const response = await fetch(azuracastAPI);
        const data = await response.json();
        setNowPlaying(data);
      } catch (error) {
        console.error('Error fetching Now Playing data:', error);
      }
    };

    fetchNowPlaying();
    // Refresh every 15 seconds
    const interval = setInterval(fetchNowPlaying, 15000);

    return () => clearInterval(interval);
  }, [azuracastAPI]);

  if (!nowPlaying || !nowPlaying.now_playing) {
    return <div className='songInfoText'>DISTORT</div>;
  }

  const { live, now_playing: { song } } = nowPlaying;

  // Determine what to display based on the live status
  let displayText = song.artist && song.title ? `${song.title} - ${song.artist}` : "Unknown Track";
  if (live && live.is_live) {
    displayText = `Live - ${live.streamer_name || "Unknown Streamer"}`;
  }

  return (
    <div>
      
      {/* <img src='burstradio.png' className='fatbelly-logo' /> */}
      <p className='songInfoText'>{displayText}</p>
      {/* Render more data as needed */}
    </div>
  );
}

export default NowPlaying;
